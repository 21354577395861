import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Container,
    VStack,
    Stack,
    Image,
    FormControl,
    FormLabel,
    Select,
    Button,
    Heading,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Link,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { createVariant, getExample, subscribeToExample } from "../hooks/useFirebase";
import CustomNumberInput from "../components/CustomNumberInput";
import SubmitButton from "../components/SubmitButton";

const LEGEND_POSITIONS = [
    { label: "Bottom", value: "bottom" },
    { label: "Top", value: "top" },
];

const COLORMAPS = [
    { label: "Blues", value: "Blues" },
    { label: "BrBG", value: "BrBG" },
    { label: "BuGn", value: "BuGn" },
    { label: "BuPu", value: "BuPu" },
    { label: "BWR", value: "bwr" },
    { label: "Cividis", value: "cividis" },
    { label: "Coolwarm", value: "coolwarm" },
    { label: "GnBu", value: "GnBu" },
    { label: "Greens", value: "Greens" },
    { label: "Greys", value: "Greys" },
    { label: "Inferno", value: "inferno" },
    { label: "Magma", value: "magma" },
    { label: "Oranges", value: "Oranges" },
    { label: "OrRd", value: "OrRd" },
    { label: "PiYG", value: "PiYG" },
    { label: "Plasma", value: "plasma" },
    { label: "PRGn", value: "PRGn" },
    { label: "PuBu", value: "PuBu" },
    { label: "PuBuGn", value: "PuBuGn" },
    { label: "PuOr", value: "PuOr" },
    { label: "PuRd", value: "PuRd" },
    { label: "Purples", value: "Purples" },
    { label: "RdBu", value: "RdBu" },
    { label: "RdGy", value: "RdGy" },
    { label: "RdPu", value: "RdPu" },
    { label: "Reds", value: "Reds" },
    { label: "RdYlBu", value: "RdYlBu" },
    { label: "RdYlGn", value: "RdYlGn" },
    { label: "Seismic", value: "seismic" },
    { label: "Spectral", value: "Spectral" },
    { label: "Viridis", value: "viridis" },
    { label: "YlGn", value: "YlGn" },
    { label: "YlGnBu", value: "YlGnBu" },
    { label: "YlOrBr", value: "YlOrBr" },
    { label: "YlOrRd", value: "YlOrRd" },
];

const FONT_COLORS = [
    { label: "Black", value: "black" },
    { label: "White", value: "white" },
];

function CustomizationPage() {
    const { exampleId } = useParams();
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(null);
    const [config, setConfig] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [variantStatus, setVariantStatus] = useState(null);

    useEffect(() => {
        const fetchExampleConfig = async () => {
            if (!exampleId) return;
            
            try {
                const example = await getExample(exampleId);
                if (example && example.config) {
                    setConfig(example.config);
                    
                    // Set up listener for example updates
                    const unsubscribe = subscribeToExample(exampleId, (updatedExample) => {
                        if (updatedExample.status === 'COMPLETED') {
                            setImageUrl(updatedExample.imageUrl);
                        } else {
                            setImageUrl(null);
                        }
                    });
                    
                    // Clean up listener
                    return () => unsubscribe();
                } else {
                    setConfig({
                        legendPosition: "bottom",
                        colormap: "coolwarm",
                        minValue: 0.0,
                        maxValue: 5.0,
                        title: "",
                        iterations: 200,
                        fontColor: "white",
                    });
                }
            } catch (error) {
                console.error("Error fetching example config:", error);
                setConfig({
                    legendPosition: "bottom",
                    colormap: "coolwarm",
                    minValue: 0.0,
                    maxValue: 5.0,
                    title: "",
                    iterations: 200,
                    fontColor: "white",
                });
            }
        };

        fetchExampleConfig();
    }, [exampleId]);

    if (!config) {
        return <Layout><Container maxW="container.xl" py={20}>Loading...</Container></Layout>;
    }

    const handleConfigChange = (field, value) => {
        setConfig(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            
            if (!exampleId) {
                console.error('No exampleId provided');
                return;
            }

            const variantId = await createVariant(exampleId, config);
            console.log('Variant ID:', variantId);
            navigate(`/customize/${variantId}`);
            
            // Set up listener for the new variant
            const unsubscribe = subscribeToExample(variantId, (updatedVariant) => {
                setVariantStatus(updatedVariant.status);
                if (updatedVariant.status === 'COMPLETED') {
                    setIsSubmitting(false);
                    unsubscribe();
                }
            });
        } catch (error) {
            console.error("Error creating variant:", error);
            setIsSubmitting(false);
        }
    };

    return (
        <Layout>
            <Container maxW="container.xl" py={20}>
                <Stack 
                    direction={{ base: 'column', lg: 'row' }} 
                    spacing={8} 
                    align="start"
                >


                    <VStack 
                        flex="1" 
                        spacing={6} 
                        align="stretch"
                        w={{ base: "100%", lg: "auto" }}
                    >
                        <Heading size="lg">Customize your math!</Heading>

                        <FormControl>
                            <FormLabel>Title</FormLabel>
                            <Input
                                value={config.title}
                                onChange={(e) => handleConfigChange('title', e.target.value)}
                                placeholder="Enter a title"
                                bg="whiteAlpha.700"
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Font Color</FormLabel>
                            <Select
                                value={config.fontColor}
                                onChange={(e) => handleConfigChange('fontColor', e.target.value)}
                                bg="whiteAlpha.700"
                            >
                                {FONT_COLORS.map(color => (
                                    <option key={color.value} value={color.value}>
                                        {color.label}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Legend Position</FormLabel>
                            <Select
                                value={config.legendPosition}
                                onChange={(e) => handleConfigChange('legendPosition', e.target.value)}
                                bg="whiteAlpha.700"
                            >
                                {LEGEND_POSITIONS.map(pos => (
                                    <option key={pos.value} value={pos.value}>
                                        {pos.label}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel>
                                Color Scheme{" "}
                                <Link
                                    color="blue.500"
                                    onClick={() => setIsModalOpen(true)}
                                    textDecoration="underline"
                                    fontSize="sm"
                                >
                                    see options
                                </Link>
                            </FormLabel>
                            <Select
                                value={config.colormap}
                                onChange={(e) => handleConfigChange('colormap', e.target.value)}
                                bg="whiteAlpha.700"
                            >
                                {COLORMAPS.map(cmap => (
                                    <option key={cmap.value} value={cmap.value}>
                                        {cmap.label}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        <Stack direction="row" spacing={4}>
                            <FormControl flex="1">
                                <FormLabel>Min Value</FormLabel>
                                <CustomNumberInput
                                    value={config.minValue}
                                    onChange={(value) => handleConfigChange('minValue', value)}
                                />
                            </FormControl>

                            <FormControl flex="1">
                                <FormLabel>Max Value</FormLabel>
                                <CustomNumberInput
                                    value={config.maxValue}
                                    onChange={(value) => handleConfigChange('maxValue', value)}
                                />
                            </FormControl>
                        </Stack>

                        <SubmitButton
                            isLoading={isSubmitting}
                            loadingText={variantStatus === 'PROCESSING' ? 'Processing...' : 'Generating...'}
                            onClick={handleSubmit}
                        >
                            Generate Custom Orbit
                        </SubmitButton>
                    </VStack>
                    <Box flex="1" w={{ base: "100%", lg: "auto" }}>
                        {imageUrl && <Image
                            src={imageUrl}
                            w="full"
                            h="auto"
                        />}
                    </Box>
                </Stack>
            </Container>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Colormap Options</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Image src="/colormaps.png" alt="Colormap options" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Layout>
    );
}

export default CustomizationPage; 