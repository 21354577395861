import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDatePicker({ 
    selected, 
    onChange, 
    disabled 
}) {
    return (
        <DatePicker
            selected={selected ? new Date(selected) : null}
            onChange={(date) => onChange(date.toISOString().split('T')[0])}
            dateFormat="dd.MM.yyyy"
            disabled={disabled}
            className="chakra-input css-1cjy4zv"
            required
            portalId="root"
            customInput={
                <input
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        opacity: '1',
                        color: '#3B4BC0',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        cursor: disabled ? 'not-allowed' : 'pointer'
                    }}
                />
            }
        />
    );
}

export default CustomDatePicker; 