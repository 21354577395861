import React from "react";
import { Box, Container, Heading, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

function Header() {
    return (
        <Box
            bg="rgba(59, 75, 192, 0.9)"
            py={4}
            shadow="sm"
            position="fixed"
            top={0}
            left={0}
            right={0}
            zIndex={1000}
        >
            <Container 
                maxW="container.xl" 
                px={0}
                display="flex"
                alignItems="left"
            >
                <Link
                    as={RouterLink}
                    to="/"
                    _hover={{ textDecoration: "none" }}
                    pl={4}
                >
                    <Heading
                        fontSize="3xl"
                        color="white"
                        fontWeight="bold"
                        fontFamily="'Computer Modern Serif', serif"
                    >
                        Beautiful Math
                    </Heading>
                </Link>
            </Container>
        </Box>
    );
}

export default Header; 