import React from 'react';
import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react';

function CustomNumberInput({ value, onChange }) {
    const handleChange = (valueString) => {
        // Allow only numbers and negative sign
        let value = valueString.replace(/[^\d-]/g, '');
        
        // Handle negative sign
        if (value.startsWith('-')) {
            value = '-' + value.substring(1).replace(/-/g, '');
        } else {
            value = value.replace(/-/g, '');
        }
        
        const finalValue = value === '' ? 0 : parseInt(value);
        onChange(finalValue);
    };

    return (
        <NumberInput
            min={0}
            step={1}
            precision={0}
            value={value}
            onChange={handleChange}
        >
            <NumberInputField bg="whiteAlpha.700" />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
}

export default CustomNumberInput; 