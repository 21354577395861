import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import {
    Container,
    VStack,
    Heading,
    Text,
    Button,
    Alert,
    AlertIcon,
    Spinner,
} from "@chakra-ui/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import Layout from "../components/Layout";

function SuccessPage() {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState("loading");
    const sessionId = searchParams.get("session_id");

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                // You'll need to create this endpoint in your Firebase functions
                const functions = getFunctions(undefined, "europe-west1");
                const verifyStripeSession = httpsCallable(
                    functions,
                    "verifyStripeSession"
                );

                const { data } = await verifyStripeSession({ sessionId });

                if (data.success) {
                    setStatus("success");
                } else {
                    setStatus("error");
                }
            } catch (error) {
                console.error("Payment verification failed:", error);
                setStatus("error");
            }
        };

        if (sessionId) {
            verifyPayment();
        } else {
            setStatus("error");
        }
    }, [sessionId]);

    if (status === "loading") {
        return (
            <Layout>
                <Container maxW="container.xl" py={20}>
                    <VStack spacing={8}>
                        <Spinner size="xl" />
                        <Text>Verifying your payment...</Text>
                    </VStack>
                </Container>
            </Layout>
        );
    }

    return (
        <Layout>
            <VStack spacing={8}>
                {status === "success" ? (
                    <>
                        <Alert status="success">
                            <AlertIcon />
                            Payment successful!
                        </Alert>
                        <Heading size="lg">
                            Thank You for Your Purchase!
                        </Heading>
                        <Text>
                            Your order has been confirmed and is being
                            processed.
                        </Text>
                    </>
                ) : (
                    <>
                        <Alert status="error">
                            <AlertIcon />
                            There was an issue verifying your payment
                        </Alert>
                        <Text>
                            Please contact support if you believe this is an
                            error.
                        </Text>
                    </>
                )}
                <Button as={Link} to="/" colorScheme="blue">
                    Return to Home
                </Button>
            </VStack>
        </Layout>
    );
}

export default SuccessPage;
