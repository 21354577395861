import React from "react";
import { Button } from "@chakra-ui/react";

function SubmitButton({ isLoading, loadingText = "Generating...", children, onClick, type = "button" }) {
    return (
        <Button
            type={type}
            colorScheme="blue"
            size="lg"
            w={{ base: "full", md: "auto" }}
            isDisabled={isLoading}
            isLoading={isLoading}
            loadingText={loadingText}
            onClick={onClick}
            _hover={{
                bg: "blue.100",
                transform: "scale(1.02)",
                boxShadow: "md"
            }}
            transition="all 0.2s"
        >
            {children}
        </Button>
    );
}

export default SubmitButton; 