import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {GetStartedButton} from "../components/GetStartedButton";
import {HowDoesItWorkBox} from "../components/HowDoesItWorkBox";

function LandingPage() {
    const navigate = useNavigate();

    return (
        <Box position="relative" minH="100vh">
            {/* Background Box */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgImage="url('../version1.png')"
                bgSize="cover"
                bgPosition="center"
                bgRepeat="no-repeat"
                bgAttachment="fixed"
                zIndex={-1}
            />
            {/* Content Box */}
            <Box
                minH="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <VStack spacing={8} mt={{ base: 40, md: 64 }}>
                    <Heading
                        as="h1"
                        fontSize={{ base: "6xl", md: "8xl", lg: "9xl" }}
                        color="white"
                        fontWeight="bold"
                        textAlign="center"
                        fontFamily="'Computer Modern Serif', serif"
                    >
                        Beautiful Math
                    </Heading>
                    <GetStartedButton />
                    <Box h={{ base: 20, md: 32 }} />
                    <HowDoesItWorkBox heading="1. Choose Your Dates" text="Choose dates that are important to you. These dates parametrize the dynamic system used for drawing." />
                    <HowDoesItWorkBox heading="2. Generate Variations" text="Generate many variations of the orbits and choose your favorite." />
                    <HowDoesItWorkBox heading="3. Customize & Ship" text="Customize your favorite image and order it." />
                    <Box h={14} />
                    <GetStartedButton />
                    <Box h={14} />
                </VStack>
            </Box>
        </Box>
    );
}

export default LandingPage;
