import React from "react";
import Header from "./Header";
import { Box } from "@chakra-ui/react";

function Layout({ children }) {
    return (
        <>
            <Box
                position="fixed"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgImage="url('../version2.png')"
                bgSize="cover"
                bgPosition="center"
                bgRepeat="no-repeat"
                zIndex="-1"
            />
            <Header />
            <Box pt="72px">
                {children}
            </Box>
        </>
    );
}

export default Layout; 