import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    VStack,
    Image,
    Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function ImageModal({ isOpen, onClose, selectedExample }) {
    const navigate = useNavigate();

    const handleContinue = () => {
        onClose();
        navigate(`/customize/${selectedExample.exampleId}`, {
            state: { imageUrl: selectedExample.imageUrl },
        });
};

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={8}>
                    <VStack spacing={6}>
                        {selectedExample && (
                            <Image
                                src={selectedExample.imageUrl}
                                alt="Selected visualization"
                                w="130%"
                                h="auto"
                            />
                        )}
                        <Button
                            colorScheme="blue"
                            size="lg"
                            onClick={handleContinue}
                        >
                            Continue
                        </Button>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ImageModal;
