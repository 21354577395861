import React, { useState } from "react";
import {
    Box,
    Container,
    VStack,
    SimpleGrid,
    useDisclosure,
    Text,
    Icon,
} from "@chakra-ui/react";
import InputDates from "../components/InputDates.js";
import ImageModal from "../components/ImageModal.js";
import ImageBox from "../components/ImageBox.js";
import { useFirebase, useTriggerRepeatedly } from "../hooks/useFirebase.js";
import Layout from "../components/Layout.js";
import { getUserId } from "../utils.js";


function GeneratePage() {
    const today = new Date().toISOString().split("T")[0];
    const [date0, setDate0] = useState(today);
    const [date1, setDate1] = useState(today);
    const [examples, setExamples] = useState([]);
    const [userId] = useState(getUserId());
    const [latestInput, setLatestInput] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isGenerating = examples.some(ex => ex.status === "PENDING" || ex.status === "PROCESSING");
    const hasCompletedImages = examples.some(ex => ex.status === "COMPLETED");

    useFirebase(userId, setExamples, date0, setDate0, date1, setDate1, latestInput, setLatestInput);
    useTriggerRepeatedly();

    const handleImageSelect = (example) => {
        if (example.status === "COMPLETED") {
            setSelectedImage(example.exampleId);
            onOpen();
        }
    };

    return (
        <Layout>
            <Box minH="100vh">
                <Container maxW="container.xl" py={20}>
                    <VStack spacing={12}>
                        <InputDates
                            userId={userId}
                            date0={date0}
                            date1={date1}
                            setDate0={setDate0}
                            setDate1={setDate1}
                            userInputId={latestInput}
                            isGenerating={isGenerating}
                        />

                        {examples.length > 0 && (

                                <Text
                                fontSize={{ base: "lg", md: "xl" }}
                                fontWeight="600"
                                color="blue.700"
                                textAlign="center"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap={2}
                            >
                                {examples.filter(ex => ex.status === "COMPLETED").length}/{examples.length} examples generated
                            </Text>
                        )}

                        {hasCompletedImages && (
                            <Box 
                                py={6}
                                px={8}
                                bg="rgba(255, 255, 255, 0.0)"
                                borderRadius="lg"
                                h="80px"
                                w="30%"
                            >
                                <Text
                                    fontSize={{ base: "lg", md: "xl" }}
                                    fontWeight="600"
                                    color="blue.700"
                                    textAlign="center"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    mt="-8 !important"
                                    gap={2}
                                >
                                    Select your favorite to continue!
                                </Text>
                            </Box>
                        )}

                        <SimpleGrid
                            columns={{ base: 1, sm: 2, lg: 3 }}
                            spacing={4}
                            w="full"
                        >
                            {examples
                                .map((example) => (
                                    <ImageBox
                                        key={example.exampleId}
                                        example={example}
                                        selectedImage={selectedImage}
                                        onSelect={handleImageSelect}
                                    />
                                ))}
                        </SimpleGrid>

                        <ImageModal
                            isOpen={isOpen}
                            onClose={onClose}
                            selectedExample={examples.find(
                                (ex) => ex.exampleId === selectedImage
                            )}
                        />
                    </VStack>
                </Container>
            </Box>
        </Layout>
    );
}

export default GeneratePage;
