import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    Box,
    Image,
    Container,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
} from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../hooks/useFirebase";
import Layout from "../components/Layout";

const stripePromise = loadStripe(
    "pk_test_51QIdHZLSSfe5p0ZOWOFiBAVOXqq3373HEHhysMQvH6gW3NIFZ3FgJGVIG3ux1mBe0p1ovASuoT7hu8bJmol0be6E000UXduRnK"
);

function OrderPage() {
    const { imageId } = useParams();
    const location = useLocation();
    const imageUrl = location.state?.imageUrl;
    const toast = useToast();

    const [formData, setFormData] = useState({
        name: "",
        address: "",
        email: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const r = await createCheckoutSession(
                imageId,
                formData.name,
                formData.address,
                formData.email
            );

            // Redirect to Stripe checkout
            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({
                sessionId: r.data.sessionId,
            });

            if (result.error) {
                throw new Error(result.error.message);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Layout>
            <Box minH="100vh">
                <Container maxW="container.xl" py={20}>
                    <VStack spacing={8}>
                        <Box>
                            <Image
                                src={imageUrl}
                                alt={`Image ${imageId}`}
                                w="full"
                                h="auto"
                            />
                        </Box>

                        <Box 
                            as="form" 
                            onSubmit={handleSubmit} 
                            w="full" 
                            maxW="md"
                            bg="rgba(255, 255, 255, 0.5)"
                            p={6}
                            borderRadius="md"
                            backdropFilter="blur(8px)"
                        >
                            <VStack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Address</FormLabel>
                                    <Input
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>

                                <Button type="submit" colorScheme="blue" w="full">
                                    Proceed to Payment
                                </Button>
                            </VStack>
                        </Box>
                    </VStack>
                </Container>
            </Box>
        </Layout>
    );
}

export default OrderPage;
