import React from "react";
import { Box, Image, Center, Text } from "@chakra-ui/react";

function ImageBox({ example, selectedImage, onSelect }) {
    return (
        <Box
            bg="white"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => onSelect(example)}
            transition="all 0.2s"
            _hover={{
                transform:
                    example.status === "COMPLETED" ? "scale(1.02)" : "none",
            }}
        >
            {(example.status === "COMPLETED") && (
                <Image
                    src={example.imageUrl}
                    alt="Math visualization"
                    w="full"
                    h="auto"
                />
            ) }
        </Box>
    );
}

export default ImageBox;
