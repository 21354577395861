import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    query,
    onSnapshot,
    orderBy,
    where,
    limit,
    addDoc,
    doc,
    updateDoc,
    getDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD9Sm5Ag5Ik1KNMV2VnLtAkyCx172tVtNs",
    authDomain: "beautiful-math-c61c2.firebaseapp.com",
    projectId: "beautiful-math-c61c2",
    storageBucket: "beautiful-math-c61c2.appspot.com",
    messagingSenderId: "958730494196",
    appId: "1:958730494196:web:85c8347adba4d3086abea1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Add debug flag - set to true for testing
const DEBUG = process.env.NODE_ENV === 'development';

const debugLog = (functionName, ...args) => {
    if (DEBUG) {
        console.log(`[DEBUG] ${functionName}:`, ...args);
    }
};

export function useFirebase(userId, setExamples, date0, setDate0, date1, setDate1, latestInputId, setLatestInputId) {

    // If userId changes, get latest user input and set the dates
    useEffect(() => {
        
        const q = query(
            collection(db, "UserInput"),
            where("userId", "==", userId),
            orderBy("createdAt", "desc"),
            limit(1)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            
            if (!snapshot.empty) {
                const latestDoc = snapshot.docs[0];
                const inputData = latestDoc.data();
                setDate0(inputData.inputData.date0);
                setDate1(inputData.inputData.date1);
            }
        });

        return () => unsubscribe();
    }, [userId]);


    // If the dates change, get the latest userInputId 
    useEffect(() => {

        const q = query(
            collection(db, "UserInput"),
            where("userId", "==", userId),
            where("inputData.date0", "==", date0),
            where("inputData.date1", "==", date1),
            orderBy("createdAt", "desc"),
            limit(1)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                const latestDoc = snapshot.docs[0];
                setLatestInputId(latestDoc.id);
            } else {
                setLatestInputId(null);
            }
            debugLog('useFirebase (on userId, date0, date1 change) - latestInputId', { latestInputId });
        });

        return () => unsubscribe();

    }, [userId, date0, date1]);

    // If latestInputId changes, get the examples
    useEffect(() => {
        
        debugLog('useFirebase (on latestInputId change) - latestInputId', { latestInputId });

        if (!latestInputId) {
            setExamples([]);
            return;
        }

        const q = query(
            collection(db, "Examples"),
            where("inputId", "==", latestInputId),
            orderBy("sequenceId", "asc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            debugLog('useFirebase - onSnapshot (Examples)', { 
                size: snapshot.size 
            });
            
            const examplesList = snapshot.docs.map((doc) => ({
                exampleId: doc.id,
                createdAt: doc.data().createdAt.toDate(),
                modifiedAt: doc.data().modifiedAt
                    ? doc.data().modifiedAt.toDate()
                    : "",
                imageUrl: doc.data().imageUrl,
                inputId: doc.data().inputId,
                userId: doc.data().userId,
                sequenceId: doc.data().sequenceId,
                status: doc.data().status,
            }));
            setExamples(examplesList);
        });

        return () => unsubscribe();
    }, [latestInputId]);
}

export function useTriggerRepeatedly() {
    useEffect(() => {
        
        const functions = getFunctions(undefined, "europe-west1");
        const triggerMessagePerExample = httpsCallable(
            functions,
            "triggerMessagePerExample"
        );

        const intervalId = setInterval(async () => {
            try {
                await triggerMessagePerExample();
            } catch (error) {
                console.error("Error triggering message per example:", error);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);
}

export async function addUserInput(userInput) {
    debugLog('addUserInput', userInput);
    const docRef = await addDoc(collection(db, "UserInput"), userInput);
    debugLog('addUserInput - success', { docId: docRef.id });
    return docRef;
}

export async function updateUserInput(userInputId, additionalExamples) {
    debugLog('updateUserInput', { userInputId, additionalExamples });
    
    const docRef = doc(db, "UserInput", userInputId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
        const currentData = docSnap.data();
        debugLog('updateUserInput - current data', currentData);
        
        await updateDoc(docRef, {
            nExamples: currentData.nExamples + additionalExamples
        });
        debugLog('updateUserInput - success');
    } else {
        debugLog('updateUserInput - error: document not found');
        throw new Error("UserInput document not found");
    }
}

export async function createCheckoutSession(imageId, name, address, email) {
    debugLog('createCheckoutSession', { imageId, name, address, email });
    
    const functions = getFunctions(undefined, "europe-west1");
    const createCheckoutSessionCf = httpsCallable(
        functions,
        "createCheckoutSession"
    );

    const result = await createCheckoutSessionCf({ imageId, name, address, email });
    debugLog('createCheckoutSession - success', result);
    
    return result;
}

export async function createVariant(exampleId, config) {
    debugLog('createVariant', { exampleId, config });
    
    const functions = getFunctions(undefined, "europe-west1");
    const createVariantFunction = httpsCallable(
        functions,
        "createVariant"
    );

    console.log('Sending to cloud function:', { exampleId, config });

    const result = await createVariantFunction({ 
        exampleId, 
        config 
    });
    
    debugLog('createVariant - success', result);
    return result.data.variantId;
}

export async function getExample(exampleId) {
    debugLog('getExample', { exampleId });
    
    try {
        const docRef = doc(db, "Examples", exampleId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            const data = docSnap.data();
            debugLog('getExample - success', data);
            return data;
        } else {
            debugLog('getExample - error: document not found');
            return null;
        }
    } catch (error) {
        debugLog('getExample - error', error);
        throw error;
    }
}

export function subscribeToExample(exampleId, callback) {
    debugLog('subscribeToExample', { exampleId });
    
    const unsubscribe = onSnapshot(
        doc(db, "Examples", exampleId),
        (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                debugLog('subscribeToExample - update received', data);
                callback(data);
            }
        },
        (error) => {
            console.error("Error subscribing to example:", error);
        }
    );

    return unsubscribe;
}
