import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import GeneratePage from "./pages/GeneratePage";
import OrderPage from "./pages/OrderPage";
import SuccessPage from "./pages/SuccessPage";
import React from "react";
import ReactDOM from "react-dom/client";
import theme from "./theme";
import "./styles.css";
import CustomizationPage from "./pages/CustomizationPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/generate" element={<GeneratePage />} />
                    <Route path="/customize/:exampleId" element={<CustomizationPage />} />
                    <Route path="/order/:imageId" element={<OrderPage />} />
                    <Route path="/success" element={<SuccessPage />} />
                </Routes>
            </Router>
        </ChakraProvider>
    </React.StrictMode>
);
