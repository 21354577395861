import React from "react";
import { Box, VStack, SimpleGrid } from "@chakra-ui/react";
import CustomDatePicker from "./CustomDatePicker";
import { addUserInput, updateUserInput } from "../hooks/useFirebase";
import SubmitButton from "./SubmitButton";

function InputDates({ 
    userId, 
    date0, 
    date1, 
    setDate0, 
    setDate1, 
    userInputId,
    isGenerating 
}) {
    const [recentlySubmitted, setRecentlySubmitted] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setRecentlySubmitted(true);
            setTimeout(() => setRecentlySubmitted(false), 7000);

            if (userInputId) {
                await updateUserInput(userInputId, 12);
            } else {
                const newInput = {
                    createdAt: new Date(),
                    userId: userId,
                    inputType: "orbits_v0",
                    inputData: { date0, date1 },
                    nExamples: 36,
                };
                await addUserInput(newInput);
            }
        } catch (error) {
            console.error("Error in handleSubmit:", error);
            setRecentlySubmitted(false);
        }
    };

    const getButtonText = () => {
        if (isGenerating || recentlySubmitted) return "Generating...";
        if (userInputId) return "Generate more";
        return "Create your beautiful math";
    };

    return (
        <Box
            as="form"
            onSubmit={handleSubmit}
            w="full"
            bg="rgba(255, 255, 255, 0.0)"
            p={8}
            borderRadius="lg"
        >
            <VStack spacing={6}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} w="full">
                    <CustomDatePicker
                        selected={date0}
                        onChange={setDate0}
                        disabled={isGenerating || recentlySubmitted}
                    />
                    <CustomDatePicker
                        selected={date1}
                        onChange={setDate1}
                        disabled={isGenerating || recentlySubmitted}
                    />
                </SimpleGrid>
                <SubmitButton
                    isLoading={isGenerating || recentlySubmitted}
                    type="submit"
                >
                    {getButtonText()}
                </SubmitButton>
            </VStack>
        </Box>
    );
}

export default InputDates;
