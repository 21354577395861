import { Box, Heading, Text } from "@chakra-ui/react";

export function HowDoesItWorkBox({heading, text}) {
    return (
        <Box textAlign="center" p={8} bg="rgba(255, 255, 255, 0.7)" borderRadius="lg" w="60%">
            <Heading as="h3" size="lg" mb={4} color="blue.600">
                        {heading}
            </Heading>
            <Text fontSize="lg">
                {text}
            </Text>
        </Box>
    );
}