import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    colors: {
        blue: {
            50:  "#3B4BC0",
            100: "#3B4BC0",
            200: "#3B4BC0",
            300: "#3B4BC0",
            400: "#3B4BC0",
            500: "#3B4BC0", // Your primary blue
            600: "#3B4BC0",
            700: "#3B4BC0",
            800: "#3B4BC0",
            900: "#3B4BC0",
        },
    },
});

export default theme; 