import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function GetStartedButton() {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate("/generate");
    };

    return (
        <Button
            bg="white"
            color="blue.600"
            size="lg"
        onClick={handleGetStarted}
        _hover={{
            bg: "gray.100",
            transform: "scale(1.02)",
            boxShadow: "md"
        }}
        transition="all 0.2s"
    >
            Get Started
        </Button>
    );
} 